import React, { useEffect, useState } from "react";
import style from "./lekkiavana.module.scss";
import avanalogo from "../../../src/image/avanalogo.png";
import landingPageforAvana from "../../image/landingPageforAvana.png";
import logo from "../../image/lekki avana PNG Logo.png";
import five from "../../image/58.jpg";
import six from "../../image/65.jpg";
import prelaunge from "../../image/prelaunge.jpg";
import prelaunge2 from "../../image/prelaunge2.jpg";
import { Form } from "react-bootstrap";
import { FaFacebook, FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import LagosFilmHouse from "../../image/LagosFilmHouse.jpg";
import alaro from "../../image/alaro.png";
import PanAtlanticUniversity from "../../image/PanAtlanticUniversity.webp";
import InternationalAirport from "../../image/International Airport.jpg";
import LekkiFreeZone from "../../image/Lekki Free Zone.jpg";
import AfricanFilmCityProject from "../../image/African Film City Project.png";
import emmy from "../../image/emmy.jpg";
import kelechi from "../../image/kelechi.jpg";
import trader from "../../image/trader.jpg";
import { FaWhatsapp } from "react-icons/fa";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { HiArrowNarrowUp } from "react-icons/hi";
import emailjs from "emailjs-com";
import SucceesModal from "../modal/success-modal";

export default function LekkiAvana() {
  const [FullName, setFullName] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [units, setUnits] = useState("");
  const [realtor, setRealtor] = useState("");
  const [hearaboutus, setHearaboutus] = useState("");
  const [load, setLoad] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const current = new Date();

  const testimonials = [
    {
      name: "Kelechi Realtor",
      title: "Pastor",
      feedback:
        "Zylus Homes made my dream of homeownership a reality! Their team was professional, transparent, and extremely helpful from the very first consultation to the closing documents. I received my dream home in the ideal location for an unbeatable price. Highly recommend!",
      image: kelechi,
    },
    {
      name: "Ayeni Emmanuel",
      title: "Emmy Dev.",
      feedback:
        "One of my biggest fears was falling into a property scam, but Bliss Homes gave me peace of mind. They provided verified listings, ensured all legal aspects were covered, and gave me confidence in my purchase. I’m now a proud homeowner! ",
      image: emmy,
    },
    {
      name: "Elizabeth Sofia",
      title: "Trader",
      feedback:
        "I was looking for a profitable real estate investment, and Zylus Homes guided me every step of the way. They provided valuable insights, helped me acquire a Lekki Avana property, and even assisted with property management. I’m now enjoying steady returns on my investment!",
      image: trader,
    },
  ];

  const SERVICE_ID = "service_m35vsyj";
  const TEMPLATE_ID = "template_hkwdohv";
  const USER_ID = "pa0TeCQGCw6VZb27j";

  const formSubmission = (e) => {
    e.preventDefault();
    setLoad(true);

    const templateParams = {
      from_name: FullName,
      to_name: EmailAddress,
      feedback: `Full Name: ${FullName},\n Email: ${EmailAddress},\n Phone Number: ${PhoneNumber},\n Units: ${units},\n Realtor: ${realtor},\n Hear About Us: ${hearaboutus},\n Date: ${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`,
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
      (result) => {
        console.log(result.text);
        setLoad(false);
        setModalShow(true);
        setFullName("");
        setEmailAddress("");
        setPhoneNumber("");
        setUnits("");
        setRealtor("");
        setHearaboutus("");
      },
      (error) => {
        console.log(error.text);
        setLoad(false);
      }
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <main id="avana">
      <div className={style.holdAllMenuParent}>
        <div className={style.holdImage}>
          <img
            src={prelaunge}
            alt="Lekki Avana"
            className={style.landingPageforAvanaKoko2}
          />
          <img
            src={landingPageforAvana}
            alt="Lekki Avana"
            className={style.landingPageforAvanaKoko}
          />
        </div>
        <div className={style.holdAllMenu}>
          <div className={style.holdAllMenu2}>
            <header className={style.header}>
              <img src={logo} alt="Lekki Avana" />
              {/* <img src={avanalogo} alt="Lekki Avana" /> */}
              <a href="tel:+2349069884912" className={style.contactus}>
                Call us
              </a>
            </header>

            <div className={style.holdHeroContent}>
              <div className={style.holdHeroContentText}>
                <h1>
                  A <span>Solar-Powered</span> Paradise
                </h1>
                <p>
                  Step into the future with a fully solar-powered estate! Say
                  goodbye to power outages and high electricity bills—Lekki
                  Avana Bungalow provides uninterrupted, clean energy for a
                  truly modern lifestyle.
                </p>
                <div className={style.holdButton2}>
                  <a href="#form" className={style.buyNow}>
                    Buy Now
                  </a>
                  {/* <button className={style.buyNow}>Buy Now</button> */}
                  <a
                    href="https://wa.me/+2349069884912"
                    className={style.contactus}
                  >
                    Contact us
                  </a>
                  {/* <button className={style.contactus}>Contact us</button> */}
                </div>
              </div>

              <div className={style.holdFormText2} id="form">
                <div className={style.holdFormText2QA}>
                  <h4>WANT TO SECURE YOUR FUTURE TODAY?</h4>
                  <div className={style.lineNowMe}>
                    <div></div>
                  </div>
                  <Form onSubmit={formSubmission}>
                    <Form.Group className="mb-3" controlId="formGridAddress2">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        placeholder="Your Full Name"
                        style={{ padding: "10px" }}
                        value={FullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridEmail">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        style={{ padding: "10px" }}
                        value={EmailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridAddress1">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        placeholder="Enter Phone"
                        style={{ padding: "10px" }}
                        type="phone"
                        value={PhoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGridAddress1">
                      <Form.Label>
                        How many units do you want to buy ?
                      </Form.Label>
                      <Form.Control
                        placeholder="Number of units"
                        style={{ padding: "10px" }}
                        type="number"
                        value={units}
                        onChange={(e) => setUnits(e.target.value)}
                        // required
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="date1">
                      <Form.Label>Are you working with a realtor ?</Form.Label>
                      <Form.Select
                        className="mb-3"
                        style={{ padding: "10px" }}
                        value={realtor}
                        onChange={(e) => setRealtor(e.target.value)}
                        required
                      >
                        <option value=""></option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="date1">
                      <Form.Label>How did you hear about us ?</Form.Label>
                      <Form.Select
                        className="mb-3"
                        style={{ padding: "10px" }}
                        value={hearaboutus}
                        onChange={(e) => setHearaboutus(e.target.value)}
                        required
                      >
                        <option value=""></option>
                        <option value="Facebook">Facebook</option>
                        <option value="Billboard">Billboard</option>
                        <option value="Instagram"> Instagram</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Google">Google</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Referred by someone">
                          Referred by someone
                        </option>
                      </Form.Select>
                    </Form.Group>

                    <button type="submit" className={style.buyNow}>
                      {load ? "Please wait..." : "Submit"}
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.resort} style={{ padding: "2rem" }}>
        <div className={style.resort2}>
          <div className={style.exclusivity}>
            <h1>Landmarks</h1>
            <div></div>
            <p>
              Located in a fast-growing, high-value area, LEKKI AVANA BUNGALOW
              gives you access to top institutions, businesses, and
              infrastructure
            </p>
          </div>

          <div className={style.holdImageNowlist}>
            <div className={style.holdImageNowlist2}>
              <div className={style.holdImageContent}>
                <img src={PanAtlanticUniversity} alt="Lekki Avana" />
                <h1>Pan Atlantic University</h1>
              </div>
              <div className={style.holdImageContent}>
                <img src={alaro} alt="Lekki Avana" />
                <h1>Alaro City</h1>
              </div>
              <div className={style.holdImageContent}>
                <img src={LagosFilmHouse} alt="Lekki Avana" />
                <h1>Lagos Film House</h1>
              </div>
              <div className={style.holdImageContent}>
                <img src={InternationalAirport} alt="Lekki Avana" />
                <h1>International Airport</h1>
              </div>
              <div className={style.holdImageContent}>
                <img src={LekkiFreeZone} alt="Lekki Avana" />
                <h1>Lekki Free Zone</h1>
              </div>
              <div className={style.holdImageContent}>
                <img src={AfricanFilmCityProject} alt="Lekki Avana" />
                <h1>African Film City Project</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style.resort} style={{ padding: "2rem" }}>
        <div className={style.resort2}>
          <div className={style.exclusivity}>
            <h1>A resort is not just a place</h1>
            <div></div>
            <p>
              It's a feeling of peace, exclusivity, and a lifetime of beautiful
              memories
            </p>
          </div>
          <div className={style.sustainable2}>
            <div className={style.sustainable}>
              <div>
                <div className={style.holdImageConte}>
                  <img src={five} alt="Lekki Avana" />
                </div>
                <div className={style.context}>
                  <h1>A Green Haven</h1>
                  <p>
                    Surrounded by lush greenery, this estate is designed for
                    serenity and peace, making it the perfect escape from the
                    city's chaos.
                  </p>
                </div>
                <div className={style.allBuyNowForm}>
                  <a href="#form">Buy Now</a>
                </div>
              </div>
              <div>
                <div className={style.holdImageConte}>
                  <img src={six} alt="Lekki Avana" />
                </div>
                <div className={style.context}>
                  <h1>A True Home</h1>
                  <p>
                    With beautifully designed bungalows LEKKI AVANA BUNGALOW
                    o􀌸ers stylish, spacious homes that redefine comfort and
                    elegance.
                  </p>
                  {/* <a href="#form">Buy Now</a> */}
                </div>
                <div className={style.allBuyNowForm}>
                  <a href="#form">Buy Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={style.resort}
        style={{ padding: "2rem", backgroundColor: "#5f9c5f2b" }}
      >
        <div className={style.resort2}>
          <div className={style.exclusivity}>
            <h1>A Destination for Leisure & Business</h1>
            <div></div>
            <p>
              Whether you’re looking for a retreat, bonding space, hotel, event
              hall, boardroom, or a professional workspace, Lekki Avana Bungalow
              offers the perfect option for you.
            </p>
          </div>

          <div className={style.iframe}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/qbmCDp1kB9w?si=30PeEn-yoXYladba"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div className={style.holdButton}>
            <button className={style.button}>Secure Your House Now</button>
          </div>
        </div>
      </div>

      <section className={style.testimonialssection}>
        <div className={style.container}>
          <h2 className={style.title}>Our Happy Investors</h2>
          <div className={style.lineMan}>
            <div></div>
          </div>
          <p className={style.description}>
            We can help you find the right place to start. But first, hear what
            our happy clients are saying.
          </p>
        </div>

        <div className={style.testimonials}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className={style.testimonialcard}>
              <div className={style.quoteicon}>
                <BiSolidQuoteAltLeft className={style.quote} />
              </div>
              <p className={style.feedback}>{testimonial.feedback}</p>
              <div className={style.profile}>
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  width={50}
                  height={50}
                  className={style.profileimage}
                />
                <div>
                  <h4 className={style.name}>{testimonial.name}</h4>
                  {testimonial.title && (
                    <p className={style.title2}>{testimonial.title}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className={style.resort}>
        <div className={style.resort2}>
          <div className={style.exclusivity}>
            <h1>Pricing & Payment Plan</h1>
            <div></div>
            <p>
              With the rise of the new Lagos in the heart of Ibeju Lekki,
              strategically positioning your business is one of the best things
              you can do right now.
            </p>
          </div>
          <div className={style.HoldnewLagos}>
            <div className={style.HoldIndustries}>
              <div className={style.holdPreLaunchDay}>
                <div className={style.holdPreLaunchDay2sd}>
                  <div className={style.holdLogo}>
                    <img
                      src={prelaunge}
                      width={400}
                      height={500}
                      alt="prelaunge"
                    />
                  </div>
                  <div className={style.holdMoney}>
                    <div className={style.holdMoney2}>
                      <img src={logo} width={90} height={70} alt="prelaunge" />
                      <h3>N58,000,000</h3>
                      <p>Without Solar</p>
                      <a href="#form">
                        <button className={style.button}>Secure a House</button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={style.holdPaymentPlan}>
                  <table>
                    <tbody>
                      <tr
                        style={{ backgroundColor: "#5f9c5f", color: "white" }}
                      >
                        <td>Months</td>
                        <td>Amount</td>
                        <td>Intial Deposit</td>
                        <td>Monthly Payment</td>
                      </tr>
                      <tr>
                        <td>0 - 3</td>
                        <td>N58,000,000</td>
                        <td>N5,000,000</td>
                        <td>N17,666,667</td>
                      </tr>
                      <tr style={{ backgroundColor: "#F6F6F6" }}>
                        <td>4 - 6</td>
                        <td>N60,900,000</td>
                        <td>N5,000,000</td>
                        <td>N9,316,667</td>
                      </tr>
                      <tr>
                        <td>7 - 12</td>
                        <td>63,800,000</td>
                        <td>N5,000,000</td>
                        <td>N4,900,000</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className={style.holdButton}>
                    <a href="#form" className={style.button}>
                      Secure Your House Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={style.HoldnewLagos}>
            <div className={style.HoldIndustries}>
              <div
                className={`${style.holdPreLaunchDay} ${style.holdPreLaunchDayasw}`}
              >
                <div className={style.holdPaymentPlan}>
                  <table>
                    <tbody>
                      <tr
                        style={{ backgroundColor: "#5f9c5f", color: "white" }}
                      >
                        <td>Months</td>
                        <td>Amount</td>
                        <td>Intial Deposit</td>
                        <td>Monthly Payment</td>
                      </tr>
                      <tr>
                        <td>0 - 3</td>
                        <td>N65,000,000</td>
                        <td>N5,000,000</td>
                        <td>N20,000,000</td>
                      </tr>
                      <tr style={{ backgroundColor: "#F6F6F6" }}>
                        <td>4 - 6</td>
                        <td>N68,250,000</td>
                        <td>N5,000,000</td>
                        <td>N10,541,667</td>
                      </tr>
                      <tr>
                        <td>7 - 12</td>
                        <td>N71,500,000</td>
                        <td>N5,000,000</td>
                        <td>N5,541,667</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className={style.holdButton}>
                    <a href="#form" className={style.button}>
                      Secure Your House Now
                    </a>
                  </div>
                </div>

                <div>
                  <div className={style.holdLogo}>
                    <img
                      src={prelaunge2}
                      width={400}
                      height={500}
                      alt="prelaunge"
                    />
                  </div>
                  <div className={style.holdMoney}>
                    <div className={style.holdMoney2}>
                      <img src={logo} width={90} height={70} alt="prelaunge" />
                      <h3>N65,000,000</h3>
                      <p>With Solar</p>
                      <a href="#form">
                        <button className={style.button}>Secure a House</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={style.resort}
        style={{
          marginTop: "4rem",
          width: "100%",
          borderTop: "1px solid whitesmoke",
          padding: "30px",
        }}
      >
        <div
          className={style.resort2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={logo} className={style.imagePre} alt="prelaunge" />
          <div className={style.holdIcons}>
            <a href="" target="_blank">
              <FaFacebook />
            </a>
            <a href="" target="_blank">
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/lekkiavana?igsh=dGsybHQ3MW04cTBq&utm_source=qr"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>

      <a
        href="https://wa.me/+2349069884912"
        className={style.whatsapp_float}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className={style.whatsappIcon} />
      </a>
      <a href="#avana" className={style.whatsapp_float2}>
        <HiArrowNarrowUp className={style.whatsappIcon2} />
      </a>
      <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
    </main>
  );
}
