import React, { useEffect, useState } from "react";
import home from "../../image/home.png";
import land from "../../image/land.png";
import p4 from "../../image/p4.png";
import p5 from "../../image/p5.png";
import p6 from "../../image/p6.png";
import p9 from "../../image/p9.png";
import lekii2Me from "../../image/lekkiPride.jpg";
import arizonmax from "../../image/arizonmax.jpg";
import ademax from "../../image/ademax.jpg";
import tiwamax from "../../image/tiwamax.jpg";
import zylusCourtApr from "../../image/zylusCourtApr.jpg";
import tana from "../../image/tana.png";
import lekkiavana2 from "../../image/lekkiavana2.jpg";
import salesclose from "../../image/salesclose.jpg";
import prime from "../../image/prime.jpg";
import arizonHomeLand from "../../image/arizonHomeLand.jpg";
import ArizonP3 from "../../image/ArizonP3.png";
import arizonestate from "../../image/arizonestate.jpg";
import energy1 from "../../image/energy 1.png";
import energy2 from "../../image/energy 2.png";
import energy3 from "../../image/energy 3.png";
import TiwaHousMag from "../../image/TiwaHousMag.png";
import energy5 from "../../image/energy5.png";
import energy6 from "../../image/energy6.png";
import energy7 from "../../image/energy7.png";
import thankgiven1 from "../../image/Rectangle 105.png";
import zylushouseplace3 from "../../image/zyluslo.png";
import kapi from "../../image/kapi.png";
import bed from "../../image/bed.png";
import pin from "../../image/pin.png";
import landbox from "../../image/landbox.png";
import homeStyle from "./Home.module.scss";
import { BsPlayCircle, BsArrowRight } from "react-icons/bs";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { IoCall } from "react-icons/io5";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Modal, Button } from "react-bootstrap";
import NewHeor from "../../image/New Hero.png";
import money from "../../image/ibeju lekki 2.jpg";
import testimonial from "../../image/testimonial.png";
import testimonial2 from "../../image/testimonial2.png";
import absolute from "../../image/absolute.jpg";
import trustline from "../../image/trustline.png";
import testimonial3 from "../../image/testimonial3.png";
import tiwaGardenPhase2 from "../../image/tiwaGardenPhase2.png";
import ArizonExplore from "../../image/ArizonExplore.png";
import tiwagardencityphase2 from "../../image/tiwagardencityphase2.jpg";
import tw2 from "../../image/tw2.jpg";
import tiwagerden2 from "../../image/tiwagerden2.jpg";
import prime2 from "../../image/prime2.jpg";
import zylusprime from "../../image/zylus-prime.jpg";
import zyluscourtExtendion from "../../image/zyluscourt extendion.jpg";
import retrustbank from "../../image/retrustbank.svg";
import testimonials from "../../DB/TestimonialsDB";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

function Home(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (e) => {
    setShow2(true);
  };

  const [tiwaCom, setTiwaCom] = useState(false);
  const handleTiwaCom2 = () => setTiwaCom(false);
  const handleTiwaCom = (e) => {
    setTiwaCom(true);
  };
  const [currentV, setCurrentV] = useState(0);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);

  const changeVideoIncrease = () => {
    setCurrentV(function (prevCount) {
      if (prevCount < 3) {
        return (prevCount += 1);
      } else {
        return (prevCount = 0);
      }
    });
  };

  const changeVideoDecrease = () => {
    setCurrentV(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 2);
      }
    });
  };

  const intervalId2 = (e) => {
    setCount2(e);
  };

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCount2(count2 + 1);
      if (count2 === 2) {
        setCount2(0);
      }
    }, 12000);
    return () => clearInterval(intervalId);
  }, [count2]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setCount(count + 1);
      if (count === 2) {
        setCount(0);
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [count]);

  const goToPage = () => {
    window.location.assign("/lekkiavana");
  };
  return (
    <section className={homeStyle.holdAll}>
      <div>
        <div
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={homeStyle.holdHeroImage}>
            <img
              src={NewHeor}
              // src={heroimage}
              alt="Heros Page"
              className={homeStyle.heroImage}
            />
          </div>
          <div className={homeStyle.holdDiscover}>
            <div className={homeStyle.holdDiscover1}>
              <h1>Discover The Ideal Property Here</h1>
              <p>
                We are committed to building strong, sustainable, proactive and
                long term relationships with both corporate and private clients.
              </p>
              <div className={homeStyle.holdExplore}>
                <Link to="/products">
                  <button className={homeStyle.explore}>Explore</button>
                </Link>
                <button className={homeStyle.holdCircle} onClick={handleShow}>
                  <BsPlayCircle className={homeStyle.circle} />
                  Play Video
                </button>
              </div>
            </div>
            {/* {isMobile ? (
              <div className={homeStyle.holdDiscover2}>
                <div>
                  {count === 0 ? (
                    <motion.div
                      initial={{ x: -100, y: 0, opacity: 0 }}
                      animate={{ x: 0, y: 0, opacity: 1 }}
                      exit={{ x: 100, y: 0, opacity: 0 }}
                      className={homeStyle.holdSmall1}
                    >
                      <div>
                        <img
                          src={kapital}
                          alt="SmallImage"
                          className={homeStyle.herosmall}
                        />
                      </div>
                      <div className={homeStyle.million}>
                        <h5>8 Million Naira </h5>
                        <p>Kapital City</p>
                        <p>500 SQM & 300 SQM</p>
                      </div>
                    </motion.div>
                  ) : null}
                  {count === 1 ? (
                    <motion.div
                      initial={{ x: -100, y: 0, opacity: 0 }}
                      animate={{ x: 0, y: 0, opacity: 1 }}
                      exit={{ x: 100, y: 0, opacity: 0 }}
                      className={homeStyle.holdSmall1}
                    >
                      <div>
                        <img
                          src={ArizonP2}
                          alt="SmallImage"
                          className={homeStyle.herosmall}
                        />
                      </div>
                      <div className={homeStyle.million}>
                        <h5>25 Million Naira</h5>
                        <p>Arizon Estate</p>
                        <p>300 SQM & 500 SQM</p>
                      </div>
                    </motion.div>
                  ) : null}
                  {count === 2 ? (
                    <motion.div
                      initial={{ x: -100, y: 0, opacity: 0 }}
                      animate={{ x: 0, y: 0, opacity: 1 }}
                      exit={{ x: 100, y: 0, opacity: 0 }}
                      className={homeStyle.holdSmall1}
                    >
                      <div>
                        <img
                          src={herosmall2P}
                          alt="SmallImage"
                          className={homeStyle.herosmall}
                        />
                      </div>
                      <div className={homeStyle.million}>
                        <h5>42 Million Naira </h5>
                        <p>Lekki Pride 1</p>
                        <p>1 - 3 Bedroom Apartment</p>
                      </div>
                    </motion.div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className={homeStyle.holdDiscover2}>
                <div>
                  <div className={homeStyle.holdSmall1}>
                    <div>
                      <img
                        src={kapital}
                        alt="SmallImage"
                        className={homeStyle.herosmall}
                      />
                    </div>
                    <div className={homeStyle.million}>
                      <h5>8 Million Naira </h5>
                      <p>Kapital City</p>
                      <p>500 SQM & 300 SQM</p>
                    </div>
                  </div>
                  <div className={homeStyle.holdSmall2}>
                    <div>
                      <img
                        src={ArizonP2}
                        alt="SmallImage"
                        className={homeStyle.herosmall}
                      />
                    </div>
                    <div className={homeStyle.million}>
                      <h5>25 Million Naira</h5>
                      <p>Arizon Estate</p>
                      <p>300 SQM & 500 SQM</p>
                    </div>
                  </div>
                  <div className={homeStyle.holdSmall3}>
                    <div>
                      <img
                        src={herosmall2P}
                        alt="SmallImage"
                        className={homeStyle.herosmall}
                      />
                    </div>
                    <div className={homeStyle.million}>
                      <h5>42 Million Naira </h5>
                      <p>Lekki Pride 1</p>
                      <p>1 - 3 Bedroom Apartment</p>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>

        <div className={homeStyle.holdHome}>
          <div
            className={homeStyle.home1}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={home} alt="Home" className={homeStyle.home} />
            <p>OVER 3,500 PLOTS ALLOCATED</p>
          </div>
          <div
            className={homeStyle.home2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={land} alt="Home" className={homeStyle.home} />
            <p>OVER 700 HOUSES DELIVERED</p>
          </div>
        </div>

        <div
          className={homeStyle.holdTiwa1M}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          {count2 === 0 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#123879" }}>Zylus Prime City</span>
                  </h3>
                  <p>
                    Zylus Prime City, is the epitome of investment prowess for
                    astute investors seeking prime opportunities for rapid
                    development and robust economic growth.
                  </p>
                  <p>
                    Nestled in the heart of Lagos,{" "}
                    <strong>Zylus Prime City.</strong>
                    emerges as the beacon for those seeking a strategically
                    positioned investment prospect in a landscape ripe for
                    exponential advancement.
                  </p>
                  <p>
                    Zylus Prime City stands as an epitome of ideal living,
                    offering a harmonious blend of modern comfort and promising
                    opportunities. With its strategic location amid notable
                    landmarks like the Tiwa Garden Phase 2 Lekki International
                    Airport, and Tiwa Commercial Hub.
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <Link to={"/zylus-prime-city"}>
                      <button className={homeStyle.explore}>Explore</button>
                    </Link>
                    <button
                      className={homeStyle.holdCircle1}
                      // onClick={handleShow1}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  <img src={zylusprime} alt="features" />
                </div>
              </div>
            </motion.div>
          ) : null}

          {/* {count2 === 2 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#633110" }}>Ade Prime Estate</span>
                  </h3>
                  <p>
                    Ade Prime Estate, is the epitome of investment prowess for
                    astute investors seeking prime opportunities for rapid
                    development and robust economic growth.
                  </p>
                  <p>
                    Nestled in the heart of Ibadan,{" "}
                    <strong>Ade Prime Estate.</strong>
                    emerges as the beacon for those seeking a strategically
                    positioned investment prospect in a landscape ripe for
                    exponential advancement.
                  </p>
                  <p>
                    Ade Prime Estate stands as an epitome of ideal living,
                    offering a harmonious blend of modern comfort and promising
                    opportunities
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <Link to={"/ade-prime-estate"}>
                      <button className={homeStyle.explore}>Explore</button>
                    </Link>
                    <button
                      className={homeStyle.holdCircle1}
                      onClick={handleShow1}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  <img src={prime2} alt="features" />
                </div>
              </div>
            </motion.div>
          ) : null} */}

          {/* {count2 === 2 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#633110" }}>Kapital City</span>
                  </h3>
                  <p>
                    KAPITAL CITY, is the epitome of investment prowess for
                    astute investors seeking prime opportunities for rapid
                    development and robust economic growth.
                  </p>
                  <p>
                    Nestled in the heart of Epe,{" "}
                    <strong>LANDMARK OF KAPITAL CITY.</strong>
                    emerges as the beacon for those seeking a strategically
                    positioned investment prospect in a landscape ripe for
                    exponential advancement.
                  </p>
                  <p>
                    KAPITAL CITY stands as an epitome of ideal living, offering
                    a harmonious blend of modern comfort and promising
                    opportunities. With its strategic location amid notable
                    landmarks like the Alaro City Refinery, Lekki Trade Zone,
                    New Airport, and Deep Sea Port.
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <a
                      href="https://www.kapitalcityestate.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className={homeStyle.explore}>Explore</button>
                    </a>
                    <button
                      className={homeStyle.holdCircle1}
                      onClick={handleShow1}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  <img src={kapi} alt="features" />
                </div>
              </div>
            </motion.div>
          ) : null} */}

          {/* {count2 === 1 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#019785" }}>
                      Tiwa Commercial Hub
                    </span>
                  </h3>
                  <p>
                    An ideal commercial hub that houses all business
                    innovations, start ups, and thriving businesses, structured
                    to promote a blend of entertainment, technology, finance and
                    wellness. Simply, a{" "}
                    <strong>LANDMARK OF COMMERCIAL HUB.</strong>
                  </p>
                  <p>
                    Tiwa Commercial hub provides numerous features both for
                    Businesses and individuals.
                  </p>
                  <p>
                    These features which are zoned in to five main categories.
                    The Health and wellness,
                    <strong>
                      Technology, Banking & Finance, Media and Warehousing.
                    </strong>
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <a
                      href="https://www.kapitalcityestate.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className={homeStyle.explore}>Explore</button>
                    </a>
                    <button
                      className={homeStyle.holdCircle1}
                      onClick={handleShow1}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  <img src={Hub1} alt="Sky" />
                </div>
              </div>
            </motion.div>
          ) : null} */}

          {count2 === 1 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#167BA4" }}>Arizon Estate</span>
                  </h3>
                  <p>
                    Arizon Estate is strategically located in an area with
                    promising growth prospects, as indicated by the presence of
                    landmarks like the Dangote Refinery, Lekki Trade Zone, New
                    Airport, and Deep Sea Port. This suggests the potential for
                    increased economic activity and infrastructure development,
                    which can positively impact property values over time.
                  </p>
                  <p>
                    Homeowners can benefit from potential appreciation in
                    property values, allowing them to build equity over time.
                    Additionally, investing in a property within a promising
                    area can offer a sense of security and stability.
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <Link to={"arizon-estate"} className={homeStyle.explore}>
                      Explore
                    </Link>
                    {/* <a
                      href="https://www.arizonestate.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className={homeStyle.explore}>Explore</button>
                    </a> */}
                    <button
                      className={homeStyle.holdCircle1}
                      onClick={handleShow2}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  <img src={ArizonExplore} alt="ArizonP3" />
                  {/* <img src={arizonestate} alt="ArizonP3" /> */}
                </div>
              </div>
            </motion.div>
          ) : null}

          {count2 === 2 ? (
            <motion.div
              initial={{ x: -100, y: 0, opacity: 0 }}
              animate={{ x: 0, y: 0, opacity: 1 }}
              exit={{ x: 100, y: 0, opacity: 0 }}
              className={homeStyle.holdTiwa}
            >
              <div className={homeStyle.holdTiwa}>
                <div className={homeStyle.holdTiwaText}>
                  <h3>
                    Discover Our Latest Product -{" "}
                    <span style={{ color: "#00312b" }}>
                      Tiwa Garden City Phase II
                    </span>
                  </h3>
                  <p>
                    Tiwa Garden City Phase 2 is strategically positioned in a
                    promising area with several key landmarks indicating
                    potential growth. The presence of institutions like Pan
                    Atlantic University suggests a focus on education and
                    intellectual development, which can attract both residents
                    and businesses seeking skilled labor and educational
                    opportunities.
                  </p>
                  <p>
                    Furthermore, the existence of Tiwa Garden Phase 1 indicates
                    a successful development track record, which is a good
                    appeal of Phase 2 to potential buyers or investors.
                    Additionally, landmarks such as the Lekki Airport Consortium
                    View, Dangote Refinery, Tiwa Commercial Hub, and the deep
                    sea port all contribute to the economic vitality of the
                    area.
                  </p>
                  <p>
                    Tiwa Garden City Phase 2 offers opportunities for increased
                    economic activity and infrastructure development, which can
                    positively impact property values over time. Homeowners and
                    investors may benefit from potential appreciation in
                    property values, allowing them to build equity and enjoy the
                    stability and security that comes with investing in
                    a promising area.
                  </p>
                  <div className={homeStyle.holdExplores}>
                    <a
                      href="https://tiwagardencity.vercel.app"
                      // href="https://www.tiwagardencity.com/phase1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className={homeStyle.explore}>Explore</button>
                    </a>
                    <button
                      className={homeStyle.holdCircle1}
                      onClick={handleTiwaCom}
                    >
                      <BsPlayCircle className={homeStyle.circle1} />
                      Play Video
                    </button>
                  </div>
                </div>
                <div className={homeStyle.holdTiwaHouses}>
                  {/* <img src={tiwagardencityphase2} alt="ArizonP3" /> */}
                  <img src={tw2} alt="ArizonP3" />
                </div>
              </div>
            </motion.div>
          ) : null}

          <div className={homeStyle.holdSwicher}>
            <div
              className={
                count2 === 0 ? homeStyle.holdSwicher1 : homeStyle.holdSwicher2
              }
              onClick={() => {
                intervalId2(0);
              }}
            ></div>
            <div
              className={
                count2 === 1 ? homeStyle.holdSwicher1 : homeStyle.holdSwicher2
              }
              onClick={() => {
                intervalId2(1);
              }}
            ></div>
            <div
              className={
                count2 === 2 ? homeStyle.holdSwicher1 : homeStyle.holdSwicher2
              }
              onClick={() => {
                intervalId2(2);
              }}
            ></div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <h1 className={homeStyle.proud}>Our Proud Partners</h1>
          <div className={homeStyle.holdProud}>
            <img src={absolute} alt="absolute" />
            <img src={trustline} alt="trustline" />
            <img src={retrustbank} alt="retrust" />
            <img src={p6} alt="p6" />
            <img src={p9} alt="p9" />
            <img src={p5} alt="p5" />
            <img src={p4} alt="p4" />
          </div>
        </div>

        <div
          className={homeStyle.holdResidencesAndLand}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={homeStyle.holdResidences}>
            <div className={homeStyle.holdResidencesArrow}>
              <h3>Our Residences</h3>
              <Link to={"/houses"}>
                <button>
                  Explore all <BsArrowRight />
                </button>
              </Link>
            </div>
            <div className={homeStyle.holdLekkiHouse}>
              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                {/* <p className={homeStyle.selling}>Selling</p> */}
                <p className={homeStyle.closed}>Sales Closed</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    // src={lekkiavana2}
                    src={salesclose}
                    alt="LikkAprt"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h5>Lekki Avana</h5>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={bed} alt="Rectangle15" />
                    <h5>3 Bed</h5>
                  </div>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>10 x 10</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  {/* <Link to={"/lekkiavana"}> */}
                  <button onClick={goToPage}>Explore Now</button>
                  {/* </Link> */}
                  <p>₦58,000,000</p>
                </div>
              </div>

              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                {/* <p className={homeStyle.selling}>Selling</p> */}
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={tana}
                    alt="LikkAprt"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h5>Lekki Pride 1</h5>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={bed} alt="Rectangle15" />
                    <h5>3 Bed</h5>
                  </div>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>10 x 10</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to={"single-houses"}>
                    <button>Explore Now</button>
                  </Link>
                  <p>₦50,000,000</p>
                </div>
              </div>

              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.out}>Sold Out</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={lekii2Me}
                    alt="lekii2Me"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h5>Lekki Pride II</h5>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={bed} alt="Rectangle15" />
                    <h5>3 Bed</h5>
                  </div>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>10 x 10</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/single-houses2">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦100,000,000</p>
                </div>
              </div> */}

              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                {/* <p className={homeStyle.out}>Out of sales</p> */}
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    // src={zyluscourtExtendion}
                    src={zylusCourtApr}
                    alt="Rectangle17"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h5>Zylus Court Extension</h5>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={bed} alt="Rectangle15" />
                    <h5>3 Bed</h5>
                  </div>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>10 x 10</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/zylus-court-extention">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦80,000,000</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={homeStyle.holdResidences}
            style={{ marginTop: "70px" }}
          >
            <div className={homeStyle.holdResidencesArrow}>
              <h3>Our Lands</h3>
              <Link to={"/lands"}>
                <button>
                  Explore all <BsArrowRight />
                </button>
              </Link>
            </div>
            <div className={homeStyle.holdLekkiHouse}>
              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={court}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Zylus Court Phase II</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="landbox" />
                    <h5>500 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/zylus-court-phase-II">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦35,000,000</p>
                </div>
              </div> */}

              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    // src={ademax}
                    src={zylusprime}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Zylus Prime City</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="landbox" />
                    <h5>300 SQM, 500 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/zylus-prime-city">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦15,000,000</p>
                </div>
              </div>

              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    // src={tiwamax}
                    src={tiwagerden2}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Tiwa Garden City Phase II</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="landbox" />
                    <h5>300 SQM, 500 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <a href="https://tiwagardencity.com/phase2">
                    <button>Explore Now</button>
                  </a>
                  <p>₦21,000,000</p>
                </div>
              </div>

              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={signum}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Signum</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="landbox" />
                    <h5>500 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/signum">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦30,000,000</p>
                </div>
              </div> */}

              <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    // src={arizonmax}
                    src={arizonHomeLand}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Arizon Estate</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>1000 SQM, 500 SQM, 300 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/arizon-estate">
                    <button>Explore Now</button>
                  </Link>
                  <p>₦21,000,000</p>
                </div>
              </div>

              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.closed}>Sales Closed</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={kapiHomeLand}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Kapital City</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="landbox" />
                    <h5>500 SQM, 300 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="https://www.kapitalcityestate.com/">
                  <button>Explore Now</button>
                  </Link>
                  <p>₦8,000,000</p>
                </div>
              </div> */}

              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.selling}>Selling</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={arizonHomeLand}
                    alt="Rectangle28"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Arizon Estate</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>500 SQM, 300 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <a href="https://arizonestate.com/">
                    <button>Explore Now</button>
                  </a>
                  <p>₦30,000,000</p>
                </div>
              </div> */}

              {/* <div
                className={homeStyle.holdLekkiHouse1}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <p className={homeStyle.closed}>Sales Closed</p>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={TiwaHubMa}
                    alt="Rectangle27"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki}>
                  <img src={pin} alt="Rectangle15" />
                  <h4>Tiwa Commercial Hub</h4>
                </div>
                <div className={homeStyle.holdBedLand}>
                  <div className={homeStyle.holdBed}>
                    <img src={landbox} alt="Rectangle15" />
                    <h5>500 SQM, 1000 SQM</h5>
                  </div>
                </div>
                <div className={homeStyle.holdBooking}>
                  <Link to="/single-land">
                  <button>Explore Now</button>
                  </Link>
                  <p>₦34,000,000</p>
                </div>
              </div> */}

              {/* <div className={homeStyle.holdLekkiHouse1}>
                                <p className={homeStyle.closed}>Sales Closed</p>
                                <div className={homeStyle.holdKokoLekkiImage}>
                                    <img src={TiwaJUH} alt="Rectangle28" className={homeStyle.kokoLekkiImage} />
                                </div>
                                <div className={homeStyle.HoldIbejuLekki}>
                                    <img src={pin} alt="Rectangle15" />
                                    <h4>Tiwa Garden City</h4>
                                </div>
                                <div className={homeStyle.holdBedLand}>
                                    <div className={homeStyle.holdBed}>
                                        <img src={landbox} alt="Rectangle15" />
                                        <h5>500 SQM, 250 SQM</h5>
                                    </div>
                                </div>
                                <div className={homeStyle.holdBooking}>
                                    <Link to="/tiwa-garden-city">
                                    <button>Explore Now</button>
                                    </Link>
                                    <p>₦35,000,000</p>
                                </div>
                            </div> */}

              {/* <div className={homeStyle.holdLekkiHouse1}>
                  <p className={homeStyle.closed}>Sales Closed</p>
                  <div className={homeStyle.holdKokoLekkiImage}>
                    <img
                      src={courtyMNJ}
                      alt="Rectangle29"
                      className={homeStyle.kokoLekkiImage}
                    />
                  </div>
                  <div className={homeStyle.HoldIbejuLekki}>
                    <img src={pin} alt="Rectangle15" />
                    <h4>Zylus Court Phase II</h4>
                  </div>
                  <div className={homeStyle.holdBedLand}>
                    <div className={homeStyle.holdBed}>
                      <img src={landbox} alt="Rectangle15" />
                      <h5>500 SQM, 250 SQM</h5>
                    </div>
                  </div>
                  <div className={homeStyle.holdBooking}>
                    <Link to="/zylus-court-phase-II">
                    <button>Explore Now</button>
                    </Link>
                    <p>₦18,000,000</p>
                  </div>
                </div> */}
            </div>
          </div>
        </div>

        <div className={homeStyle.holdComfort}>
          <div
            className={homeStyle.holdPriority}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <h3>We Make Your Comfort Our Top Priority.</h3>
            <p>
              We have always set a hallmark for sophisticated urban living and
              features unique and elegant designs tailored to meet the needs of
              the modern-day investor as well as appeal to aesthetics.
            </p>
            <div className={homeStyle.holdEnergy}>
              <div>
                <img src={energy5} alt="energy 1" />
                24/7 Electricity
              </div>
              <div>
                <img src={energy3} alt="energy 1" />
                Security
              </div>
              <div>
                <img src={energy2} alt="energy 1" />
                Drainage
              </div>
              <div>
                <img src={energy1} alt="energy 1" />
                Clean Energy
              </div>
              <div>
                <img src={energy6} alt="energy 1" />
                Perimeter Fencing
              </div>
              <div>
                <img src={energy7} alt="energy 1" />
                Estate Mall
              </div>
            </div>
            <Link to="/houses">
              <button>Explore</button>
            </Link>
          </div>
          <div
            className={homeStyle.holdRectangle31}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={TiwaHousMag} alt="Rectangle31" />
          </div>
        </div>

        <div>
          <div
            className={homeStyle.holdLine}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div></div>
          </div>
          <h1
            className={homeStyle.eventText}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            News & Event
          </h1>
          <div
            className={homeStyle.holdResidencesNme}
            style={{ marginTop: "70px" }}
          >
            <div className={homeStyle.holdNews}>
              <div
                className={homeStyle.holdLekkiHouse12}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={zylushouseplace3}
                    alt="Rectangle27"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki2}>
                  <button className={homeStyle.publish}>Published</button>
                </div>
                <div className={homeStyle.holdBedLand2}>
                  <Link
                    to="/pioneering-international-real-estate-excellence-in-nigeria"
                    className={homeStyle.holdBed2}
                  >
                    <h5 className={homeStyle.chanllenge}>
                      Zylus Homes: Pioneering International Real Estate
                      Excellence in Nigeria
                    </h5>
                  </Link>
                </div>
                <div className={homeStyle.holdBooking2}>
                  <p>July 09, 2024</p>
                </div>
              </div>

              <div
                className={homeStyle.holdLekkiHouse12}
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={thankgiven1}
                    alt="Rectangle27"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki2}>
                  <button className={homeStyle.publish}>Published</button>
                </div>
                <div className={homeStyle.holdBedLand2}>
                  <Link to="/lagos-real-estate" className={homeStyle.holdBed2}>
                    <h5 className={homeStyle.chanllenge}>
                      Beyond the Horizon: Exploring the Untapped Potential of
                      Lagos Real Estate
                    </h5>
                  </Link>
                </div>
                <div className={homeStyle.holdBooking2}>
                  <p>May 03, 2024</p>
                </div>
              </div>

              <div
                className={homeStyle.holdLekkiHouse12}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={money}
                    alt="Rectangle47"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki2}>
                  <button className={homeStyle.publish}>Published</button>
                </div>
                <div className={homeStyle.holdBedLand2}>
                  <Link to={"/blog3"} className={homeStyle.holdBed2}>
                    <h5 className={homeStyle.chanllenge}>
                      Discover Unmatched Luxury and Investment Potential:
                      Estates in Ibeju Lekki
                    </h5>
                  </Link>
                </div>
                <div className={homeStyle.holdBooking2}>
                  <p>Apr 24, 2024</p>
                </div>
              </div>
              {/* <div className={homeStyle.holdLekkiHouse1}>
                <div className={homeStyle.holdKokoLekkiImage}>
                  <img
                    src={FuelSubsideyRemoval}
                    alt="Rectangle29"
                    className={homeStyle.kokoLekkiImage}
                  />
                </div>
                <div className={homeStyle.HoldIbejuLekki2}>
                  <button className={homeStyle.publish}>Published</button>
                </div>
                <div className={homeStyle.holdBedLand2}>
                  <Link to={"/blog4"} className={homeStyle.holdBed2}>
                    <h5 className={homeStyle.chanllenge}>
                      Fuel Subsidy Removal: A Call to Increase Your Revenue
                      Strategically.
                    </h5>
                  </Link>
                </div>
                <div className={homeStyle.holdBooking2}>
                  <p>August 11, 2023</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <section className={homeStyle.holdAllTestimonial} data-aos="fade-up">
          <div className="container mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-800">Testimonials</h2>
            <p className="text-gray-600 mt-4">What our clients tell us</p>
          </div>

          <div className={homeStyle.holdGrid}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className={homeStyle.gridShow}>
                <div className={homeStyle.iconNow}>
                  <BiSolidQuoteAltLeft />
                </div>
                <p>{testimonial.feedback}</p>
                <div className={homeStyle.holdName}>
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    width={50}
                    height={50}
                    className=""
                  />
                  <div>
                    <h4 className="">{testimonial.name}</h4>
                    {testimonial.title && (
                      <p className="">{testimonial.title}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* <div>
          <div
            className={homeStyle.holdLine}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div></div>
          </div>
          <h1
            className={homeStyle.Testimonials}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            Testimonials
          </h1>
          <h1
            className={homeStyle.clients}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            What our clients tell us
          </h1>
          <div className={homeStyle.holdAlabiSamue}>
            <div
              className={homeStyle.testimonialNew}
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={homeStyle.testimonialNewText}>
                <p>
                  “I’m very happy and excited to be getting my allocation today
                  in just 1month after completing payment. Zylus homes came
                  through and delivered as promised”
                </p>
                <h4>Mr Adeola</h4>
                <h6>Journalist</h6>
              </div>
              <div className={homeStyle.testimonialNewImage}>
                <img src={testimonial} alt="testimonial" />
              </div>
            </div>

            <div
              className={homeStyle.testimonialNew}
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={homeStyle.testimonialNewText}>
                <p>
                  Tiwa is a wonderful place to have a plot in and coming here to
                  see it myself is like a dream come through for everyone who
                  intends to grow and be wealthy. Zylus homes thank you for
                  giving me the opportunity to secure a plot here.
                </p>
                <h4>Pastor Daniel</h4>
                <h6>Trader</h6>
              </div>

              <div className={homeStyle.testimonialNewImage}>
                <img src={testimonial2} alt="testimonial" />
              </div>
            </div>

            <div
              className={homeStyle.testimonialNew}
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={homeStyle.testimonialNewText}>
                <p>
                  “It has been amazing doing business with zylus homes unlike
                  other firms that don’t get allocated on time. Zylus has made
                  every process seamless from payment to allocation. Thank you
                  Zylus for making my dream come through within 1 month”
                </p>
                <h4>Adebakin Temidayo</h4>
                <h6>Trader</h6>
              </div>
              <div className={homeStyle.testimonialNewImage}>
                <img src={testimonial3} alt="testimonial" />
              </div>
            </div>
            <div className={homeStyle.AlabiSamuel}>
              <div className={homeStyle.AlabiSamue2}>
                <div>
                  <img
                    src={Ellipse1}
                    alt="Ellipse1"
                    className={homeStyle.Ellipse1}
                  />
                </div>
                <div className={homeStyle.MediaDev}>
                  <h1>Mrs Adeola</h1>
                  <p className={homeStyle.Dev}>Journalist</p>
                  <p>
                    I’m very happy and excited to be getting my allocation today
                    in just 1month after completing payment. Zylus homes came
                    through and delivered as promised
                  </p>
                </div>
              </div>
            </div>
            <div className={homeStyle.AlabiSamuel + "" + homeStyle.testOO}>
              <div className={homeStyle.AlabiSamue2}>
                <div>
                  <img
                    src={Ellipse2}
                    alt="Ellipse2"
                    className={homeStyle.Ellipse1}
                  />
                </div>
                <div className={homeStyle.MediaDev}>
                  <h1>Adebakin Temidayo</h1>
                  <p className={homeStyle.Dev}>Trader</p>
                  <p>
                    It has been amazing doing business with zylus homes unlike
                    other firms that don’t get allocated on time. Zylus has made
                    every process seamless from payment to allocation. Thank you
                    Zylus for making my dream come through within 1 month.
                  </p>
                </div>
              </div>
            </div>
            <div className={homeStyle.AlabiSamuel}>
              <div className={homeStyle.AlabiSamue2}>
                <div>
                  <img
                    src={Ellipse3}
                    alt="Ellipse3"
                    className={homeStyle.Ellipse1}
                  />
                </div>
                <div className={homeStyle.MediaDev}>
                  <h1>Pastor Daniel</h1>
                  <p className={homeStyle.Dev}>Evangelist</p>
                  <p>
                    Tiwa is a wonderful place to have a plot in and coming here
                    to see it myself is like a dream come through for everyone
                    who intends to grow and be wealthy. Zylus homes thank you
                    for giving me the opportunity to secure a plot here.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={homeStyle.holdFaGreater}>
            <button className={homeStyle.FaLessThan}>
              <FaLessThan />
            </button>
            <button className={homeStyle.faGreater}>
              <FaGreaterThan />
            </button>
          </div>
        </div> */}

        <div
          className={homeStyle.holdNewsletter}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={homeStyle.Newsletter}>
            <h1>Subscribe to our Newsletter</h1>
            <h1>Get updates on our products</h1>
          </div>
          <div className={homeStyle.NewsletterSubscribe}>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email Address" />
            <button>Subscribe</button>
          </div>
        </div>

        <div
          className={homeStyle.holdAnytime}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div>
            <h1 className={homeStyle.Reach}>You Can Reach Us Anytime</h1>
            <p className={homeStyle.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <a
            href="tel:+2347061032706"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className={homeStyle.callUs}>
              <IoCall />
              Call us
            </button>
          </a>
        </div>

        <div className={homeStyle.holdLine2}>
          <div></div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Gallery Videos of our Estates</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentV === 0 && (
            <iframe
              src="https://www.youtube.com/embed/o2J0hdRVKwI?si=A7gWGA2UsXL4hNqF"
              width="100%"
              height="300px"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {currentV === 1 && (
            <iframe
              src="https://www.youtube.com/embed/Fso7BVxLp2U?si=pK5aBsxY254HtCxE"
              width="100%"
              height="300px"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {currentV === 2 && (
            <iframe
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/Fv9JWmDxx8k?si=KMOEJwUIiunSyYMp"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
          {currentV === 3 && (
            <iframe
              width="100%"
              height="300px"
              title="YouTube video player"
              frameborder="0"
              src="https://www.youtube.com/embed/3RuYVrEBdAU?si=wqzCbqCq6OrsyChD"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className={homeStyle.holdFooterModal}>
            <button onClick={changeVideoDecrease}>Previous</button>
            <button onClick={changeVideoIncrease}>Next</button>
          </div>
          {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>What to Expect in Ade Prime</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/Fso7BVxLp2U"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>What to Expect in Kapital City</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/LtkjMz5ERpU"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Arizon Estate 3D</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/8S0lR7rmvEw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={tiwaCom} onHide={handleTiwaCom2}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>Tiwa Garden City Phase II</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/cEdUSwwEjNs?si=NVsbVxn4_wa2GoWO"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTiwaCom2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Home;
