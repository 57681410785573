import React, { useEffect, useState } from "react";
import { BsPlayCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { BiFile } from "react-icons/bi";
import singleHouse from "./singleHouses.module.scss";
import homesecurity from "../../../../image/home-security.png";
import drainage from "../../../../image/drainage.png";
import Ho2 from "../../../../image/Ho2.png";
import Ho3 from "../../../../image/Ho3.png";
import Ho4 from "../../../../image/Ho4.png";
import BED2 from "../../../../image/BED2.png";
import BED3 from "../../../../image/BED3.png";
import BED4 from "../../../../image/BED4.png";
import road from "../../../../image/road.png";
import cctvCamera from "../../../../image/cctv-camera.png";
import fence from "../../../../image/fence.png";
import lekkipride2Sign1 from "../../../../image/lekkipride2Sign1.png";
import lekkipride2Sign2 from "../../../../image/lekkipride2Sign2.png";
import lekkipride2Sign3 from "../../../../image/lekkipride2Sign3.png";
import zyluscourtExtendion from "../../../../image/zyluscourt extendion.jpg";
import zyluscourtextension2 from "../../../../image/zyluscourtextension2.jpg";
import zyluscourtextendion3 from "../../../../image/zyluscourt extendion3.jpg";
import { Form, Row, Col } from "react-bootstrap";
import { IoCall } from "react-icons/io5";
import { Modal, Button } from "react-bootstrap";
import { db } from "../../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import SucceesModal from "../../../modal/success-modal";


function ZylusCout(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [social, setSocial] = useState("");
  const [category, setCategory] = useState("");
  const contactCollectionRef = collection(db, "house-reservations");
  const current = new Date();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    addDoc(contactCollectionRef, {
      fullName: fullName,
      phone: phone,
      email: email,
      social: social,
      category: category,
      date: `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`,
      read: false,
    })
      .then(() => {
        setLoad(false);
        setModalShow(true);
        setFullName("");
        setPhone("");
        setEmail("");
        setCategory("");
        setSocial("");
      })
      .catch((error) => {
        alert("Error occurred", error);
        setLoad(false);
      });
  };

  useEffect(() => {
    const ifameData = document.getElementById("iframeId");
    const lat = 6.440065356936118;
    const lon = 3.464997996111175;
    ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={singleHouse.holdALl}>
      <div>
        <div
          className={singleHouse.holdHouses}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <img src={zyluscourtExtendion} alt="houses" />
          <div className={singleHouse.holdHousesText}>
            <h1>Zylus Court Extension</h1>
            <p>Bogije Ibeju Lekki Lagos</p>
            <p>(Certificate Of Occupancy)</p>
            {/* <div>
              <button
              onClick={handleShow}
              >
                <BsPlayCircle />
                Play Video
              </button>
              <button>
                <BiFile />
                Brochure
              </button>
            </div> */}
          </div>
        </div>

        <div
          className={singleHouse.holdProvision}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={singleHouse.holdProvision2}>
            <div className={singleHouse.blackHeadText}>
              <h1>Zylus Court Extension</h1>
              <p>General Features </p>
            </div>
            <div className={singleHouse.holdImageText}>
              <div>
                <img src={drainage} alt="drainage" />
                <p>Drainage</p>
              </div>
              <div>
                <img src={road} alt="road" />
                <p>Roads</p>
              </div>
              <div>
                <img src={cctvCamera} alt="cctvCamera" />
                <p>Surveillance</p>
              </div>
              <div>
                <img src={fence} alt="fence" />
                <p>Gated House</p>
              </div>
              <div>
                <img src={homesecurity} alt="fence" />
                <p>Home Security</p>
              </div>
            </div>
          </div>
        </div>

        <div className={singleHouse.holdApartment}>
          <div className={singleHouse.holdApartment2}>
            <div className={singleHouse.holdBedroom}>
              <h1>
                <span className={singleHouse.holdBedroomSPan2}>
                  Semi Finished
                </span>
                <span className={singleHouse.holdBedroomSPan1}>
                  3 Bedroom Terrace Duplex + BQ
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N80M</span>
              </h1>
              {/* <h1>
                <span className={singleHouse.holdBedroomSPan2}>Finished</span>
                <span className={singleHouse.holdBedroomSPan1}>
                  2 Bedroom Fully Detached Duplex
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N90M</span>
              </h1> */}
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension, 3 bedroom terrace duplex consists of
                  three rooms and Living room.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  This gives you benefit to earn rental income from your
                  investment at Zylus Court Extension annually after full
                  payment completion or even earn daily, weekly and monthly from
                  shortlets /Airbnb.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension is strategically located at Bogije Ibeju
                  Lekki Lagos.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension apartment offers spacious units of
                  apartments tailored to meet your highest standards.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension is located after Bogije Ibeju Lekki
                  Lagos. It is an estimated 30 minutes drive to Victoria Island
                  without traffic.
                </p>
              </div>
            </div>
            <div className={singleHouse.holdBedroom2}>
              <img src={zyluscourtExtendion} alt="lekkipride2Sign2" />
            </div>
          </div>
          <div className={singleHouse.holdSMallImage}>
            <div className={singleHouse.holdSMallImage2}>
              <div>
                <img src={Ho2} alt="Rectangle31" />
              </div>
              <div>
                <img src={Ho3} alt="Rectangle31" />
              </div>
              <div>
                <img src={Ho4} alt="Rectangle31" />
              </div>
            </div>
          </div>
        </div>

        <div className={singleHouse.holdApartments}>
          <div className={singleHouse.holdApartment2s}>
            <div className={singleHouse.holdBedroom}>
              <h1>
                <span className={singleHouse.holdBedroomSPan2}>
                  Semi Finished
                </span>
                <span className={singleHouse.holdBedroomSPan1}>
                  3 Bedroom Terrace Duplex
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N75M</span>
              </h1>
              {/* <h1>
                <span className={singleHouse.holdBedroomSPan2}>Finished</span>
                <span className={singleHouse.holdBedroomSPan1}>
                  3 Bedroom Semi-Detached Duplex + BQ
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N110M</span>
              </h1> */}
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension, 3 Bedroom Semi-Detached Duplex consists
                  of 3 bedrooms apartment, 1 living room
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  This gives you benefit to earn rental income from your
                  investment at Zylus Court Extension annually after full
                  payment completion or even earn daily, weekly and monthly from
                  shortlets /Airbnb.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension is strategically located at Bogije Ibeju
                  Lekki Lagos.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension apartment offers spacious units of
                  apartments tailored to meet your highest standards.
                </p>
              </div>
              {/* <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Abraham Adesanya estate is located after Ajah, off the Lekki –
                  Epe expressway. It is an estimated 30 minutes drive to
                  Victoria Island without traffic.
                </p>
              </div> */}
            </div>
            <div className={singleHouse.holdBedroom2}>
              <img src={zyluscourtextension2} alt="lekkipride2Sign3" />
            </div>
          </div>
          <div className={singleHouse.holdSMallImages}>
            <div className={singleHouse.holdSMallImage2s}>
              <div>
                <img src={BED2} alt="Rectangle31" />
              </div>
              <div>
                <img src={BED3} alt="Rectangle31" />
              </div>
              <div>
                <img src={BED4} alt="Rectangle31" />
              </div>
            </div>
          </div>
        </div>

        <div className={singleHouse.holdApartment}>
          <div className={singleHouse.holdApartment2}>
            <div className={singleHouse.holdBedroom}>
              <h1>
                <span className={singleHouse.holdBedroomSPan2}>
                  Semi Finished
                </span>
                <span className={singleHouse.holdBedroomSPan1}>
                  3 Bedroom Terrace Duplex + BQ
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N90M</span>
              </h1>
              {/* <h1>
                <span className={singleHouse.holdBedroomSPan2}>Finished</span>
                <span className={singleHouse.holdBedroomSPan1}>
                  2 Bedroom Fully Detached Duplex
                </span>
                <span className={singleHouse.holdBedroomSPan2}>N90M</span>
              </h1> */}
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension, 3 bedroom terrace duplex consists of
                  three rooms and Living room.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  This gives you benefit to earn rental income from your
                  investment at Zylus Court Extension annually after full
                  payment completion or even earn daily, weekly and monthly from
                  shortlets /Airbnb.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension is strategically located at Bogije Ibeju
                  Lekki Lagos.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension apartment offers spacious units of
                  apartments tailored to meet your highest standards.
                </p>
              </div>
              <div>
                <div>
                  <BsFillCheckCircleFill />
                </div>
                <p>
                  Zylus Court Extension is located after Bogije Ibeju Lekki
                  Lagos. It is an estimated 30 minutes drive to Victoria Island
                  without traffic.
                </p>
              </div>
            </div>
            <div className={singleHouse.holdBedroom2}>
              <img src={zyluscourtextendion3} alt="lekkipride2Sign2" />
            </div>
          </div>
          <div className={singleHouse.holdSMallImage}>
            <div className={singleHouse.holdSMallImage2}>
              <div>
                <img src={Ho2} alt="Rectangle31" />
              </div>
              <div>
                <img src={Ho3} alt="Rectangle31" />
              </div>
              <div>
                <img src={Ho4} alt="Rectangle31" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className={singleHouse.holdApartment}>
            <div className={singleHouse.holdApartment2}>
              <div className={singleHouse.holdBedroom}>
                <h1>
                  <span className={singleHouse.holdBedroomSPan2}>
                    Semi Finished
                  </span>
                  <span className={singleHouse.holdBedroomSPan1}>
                    3 Bedroom Terrace Duplex + BQ{" "}
                  </span>
                  <span className={singleHouse.holdBedroomSPan2}>N66M</span>
                </h1>
                <h1>
                  <span className={singleHouse.holdBedroomSPan2}>Finished</span>
                  <span className={singleHouse.holdBedroomSPan1}>
                    3 Bedroom Terrace Duplex + BQ{" "}
                  </span>
                  <span className={singleHouse.holdBedroomSPan2}>N75M</span>
                </h1>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride II, 3 Bedroom Semi-Detached Duplex + BQ consists
                    of 3 bedrooms Terrace apartment, 1 living room and a BQ
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    This gives you benefit to earn rental income from your
                    investment at lekki pride annually after full payment
                    completion or even earn daily, weekly and monthly from
                    shortlets /Airbnb.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride II is strategically located at Ogombo Road, by
                    Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki PrideII apartment offers spacious units of apartments
                    tailored to meet your highest standards.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Abraham Adesanya estate is located after Ajah, off the Lekki
                    – Epe expressway. It is an estimated 30 minutes drive to
                    Victoria Island without traffic.
                  </p>
                </div>
              </div>
              <div className={singleHouse.holdBedroom2}>
                <img src={lekkipride2Sign2} alt="lekkipride2Sign2" />
              </div>
            </div>

            <div className={singleHouse.holdSMallImage}>
              <div className={singleHouse.holdSMallImage2}>
                <div>
                  <img src={HoTH3} alt="Rectangle31" />
                </div>
                <div>
                  <img src={HoTH4} alt="Rectangle31" />
                </div>
                <div>
                  <img src={HoTH5} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className={singleHouse.holdApartment}>
            <div className={singleHouse.holdApartment2}>
              <div className={singleHouse.holdBedroom2}>
                <img src={lekkipride2Sign3} alt="lekkipride2Sign3" />
              </div>

              <div className={singleHouse.holdBedroom}>
                <h1>
                  <span className={singleHouse.holdBedroomSPan1}>
                    4 Bedroom Semi-Detached
                  </span>
                  <span className={singleHouse.holdBedroomSPan2}>N80M</span>
                </h1>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride II, 4 Bedroom Semi-Detached Duplex + BQ consists
                    of 4 bedrooms apartment, 1 living room and a BQ
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    This gives you benefit to earn rental income from your
                    investment at Lekki pride II annually after full payment
                    completion or even earn daily, weekly and monthly from
                    shortlets /Airbnb.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride II is strategically located at Ogombo Road, by
                    Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride II apartment offers spacious units of apartments
                    tailored to meet your highest standards.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Abraham Adesanya estate is located after Ajah, off the Lekki
                    – Epe expressway. It is an estimated 30 minutes drive to
                    Victoria Island without traffic.
                  </p>
                </div>
              </div>
            </div>

            <div className={singleHouse.holdSMallImage}>
              <div className={singleHouse.holdSMallImage2}>
                <div>
                  <img src={HoTH3} alt="Rectangle31" />
                </div>
                <div>
                  <img src={HoTH4} alt="Rectangle31" />
                </div>
                <div>
                  <img src={HoTH5} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div> */}

        {/* <div className={singleHouse.holdLekkiPrideIEstate}>
          <div className={singleHouse.holdLekkiPrideIEstate2}>
            <h1>Lekki Pride I Estate</h1>
            <div>
              <p>
                Lekki Pride II apartment offers spacious units of apartments
                tailored to meet your highest standards, with excellently
                designed luxury homes, that brings comfort, luxury and
                appreciation overtime.
              </p>
              <p>
                Lekki Pride Estate is strategically located at Ajiwe Bus Stop,
                by Abraham Adesanya Roundabout, Lekki-Epe Expressway.
              </p>
              <p>
                Lekki Pride II estate is located after Abraham Adesanya, Ogombo
                Road. It is an estimated 30 minutes drive to Victoria Island
                without traffic
              </p>
            </div>
            <h1>Proximity</h1>
            <div>
              <p>
                Eti-Osa Maternal And Child Care Center, Ajiwe Police Station
                Agofure Motors, Conoil Filling Station , God is God Motor,
                Park,Royal Garden Estate.
              </p>
            </div>
            <h1>C of O</h1>
                        <div>
                            <p>Initial Deposit Required- N5,000,000</p>
                        </div>
                        <h1>Selling Price</h1>
                        <div>
                            <p>3 Bedroom Apartment – 46M</p>
                            <p>2 Bedroom Apartment – 40M</p>
                            <p>1 Bedroom Apartment – 36M</p>
                        </div>
          </div>
        </div> */}

        <div className={singleHouse.holdLekkiPrideIEstateM}>
          <div className={singleHouse.holdLekkiPrideIEstate2M}>
            <h4>Make your Reservation</h4>
            <div className={singleHouse.holdLekkiPrideIEstate2M2}>
              <div className={singleHouse.holdForm}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      style={{ border: "2px solid #ffcc29" }}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        style={{ border: "2px solid #ffcc29" }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Phone Number"
                        style={{ border: "2px solid #ffcc29" }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formGridState"
                  >
                    <Form.Label>How did you hear about us?</Form.Label>
                    <Form.Select
                      defaultValue=""
                      style={{ border: "2px solid #ffcc29" }}
                      value={social}
                      onChange={(e) => setSocial(e.target.value)}
                      required
                    >
                      <option></option>
                      <option>Linkedin</option>
                      <option>Instagram</option>
                      <option>Facebook</option>
                      <option>Twitter</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formGridState"
                  >
                    <Form.Label>Are you working with a Realtor</Form.Label>
                    <Form.Select
                      defaultValue=""
                      style={{ border: "2px solid #ffcc29" }}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      required
                    >
                      <option></option>
                      <option>Yes</option>
                      <option>No</option>
                    </Form.Select>
                  </Form.Group>

                  <button className={singleHouse.formButton}>
                    {load ? "Please wait..." : "Submit"}
                  </button>
                </Form>
              </div>
              <div className={singleHouse.holdMap}>
                <iframe
                  id="iframeId"
                  title="Map"
                  className={singleHouse.mapKoko}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className={singleHouse.holdAnytime}>
          <div>
            <h1 className={singleHouse.Reach}>You Can Reach Us Anytime</h1>
            <p className={singleHouse.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={singleHouse.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>LEKKI PRIDE PHASE II</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/wcEwxEPxdLE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
}

export default ZylusCout;
